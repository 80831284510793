import { FocusableComponentLayout } from '@noriginmedia/norigin-spatial-navigation';
import {
  AssetImage,
  AssetV2,
  CategoryV2,
  Maybe,
  ModuleAssetPromo,
  ModuleCategory,
  ModuleCollage,
  ModuleCollageCollection,
  ModuleCollageThumbnail,
  ModuleCollection,
  ModuleDynamicCollection,
  ModulePreviewCollection,
  Need,
} from '@srgssr/rio-shared';

import { RANKING_SPACING, RANKING_TEASER_HEIGHT, RANKING_TEASER_WIDTH } from '../components/teasers/RankingTeaser';
import {
  RICH_ITEM_HEIGHT,
  RICH_ITEM_WIDTH,
  TEASER_AUDIO_HEIGHT,
  TEASER_AUDIO_WIDTH,
  TEASER_FOCUS_INDICATOR_SIZE,
  TEASER_HEIGHT,
  TEASER_WIDTH,
} from '../components/teasers/Teaser';
import { scaleY, WINDOW_HEIGHT } from './window';

export const LIST_MODULE_ITEM_FOCUS_INDICATOR_SIZE = TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_ITEM_WIDTH = TEASER_WIDTH + LIST_MODULE_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_ITEM_HEIGHT = TEASER_HEIGHT + LIST_MODULE_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_ITEM_SPACE = 26;
export const LIST_MODULE_ITEM_WIDTH_WITH_SPACE = LIST_MODULE_ITEM_WIDTH + LIST_MODULE_ITEM_SPACE;

export const LIST_MODULE_AUDIO_ITEM_FOCUS_INDICATOR_SIZE = TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_AUDIO_ITEM_WIDTH = TEASER_AUDIO_WIDTH + LIST_MODULE_AUDIO_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_AUDIO_ITEM_HEIGHT = TEASER_AUDIO_HEIGHT + LIST_MODULE_AUDIO_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_AUDIO_ITEM_SPACE = 26;
export const LIST_MODULE_AUDIO_ITEM_WIDTH_WITH_SPACE = LIST_MODULE_AUDIO_ITEM_WIDTH + LIST_MODULE_AUDIO_ITEM_SPACE;

export const LIST_MODULE_RICH_ITEM_FOCUS_INDICATOR_SIZE = TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_RICH_ITEM_WIDTH = RICH_ITEM_WIDTH + LIST_MODULE_RICH_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_RICH_ITEM_HEIGHT = RICH_ITEM_HEIGHT + LIST_MODULE_RICH_ITEM_FOCUS_INDICATOR_SIZE * 2;
export const LIST_MODULE_RICH_ITEM_SPACE = 40;
export const LIST_MODULE_RICH_ITEM_HEIGHT_WITH_SPACE = RICH_ITEM_HEIGHT + LIST_MODULE_RICH_ITEM_SPACE;

export const LIST_MODULE_RANKING_TEASER_FOCUS_INDICATOR_SIZE = TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_RANKING_TEASER_WIDTH = RANKING_TEASER_WIDTH + RANKING_SPACING + LIST_MODULE_RANKING_TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_RANKING_TEASER_HEIGHT = RANKING_TEASER_HEIGHT + LIST_MODULE_RANKING_TEASER_FOCUS_INDICATOR_SIZE;
export const LIST_MODULE_RANKING_TEASER_SPACE = 26;
export const LIST_MODULE_RANKING_TEASER_WIDTH_WITH_SPACE = LIST_MODULE_RANKING_TEASER_WIDTH + LIST_MODULE_RANKING_TEASER_SPACE;

export const ASSET_PROMO_MODULE_ITEM_HEIGHT = 312;
export const CATEGORIES_MODULE_ITEM_HEIGHT = 246;

export const MODULE_TITLE_HEIGHT = 49;
export const MODULE_MARGIN_BOTTOM = 60;
export const LIST_MODULE_HEIGHT =
  scaleY(LIST_MODULE_ITEM_HEIGHT, null) + scaleY(MODULE_TITLE_HEIGHT, null) + scaleY(MODULE_MARGIN_BOTTOM, null);
export const LIST_MODULE_AUDIO_HEIGHT =
  scaleY(LIST_MODULE_AUDIO_ITEM_HEIGHT, null) + scaleY(MODULE_TITLE_HEIGHT, null) + scaleY(MODULE_MARGIN_BOTTOM, null);
export const ASSET_PROMO_MODULE_HEIGHT =
  scaleY(ASSET_PROMO_MODULE_ITEM_HEIGHT, null) + scaleY(MODULE_TITLE_HEIGHT, null) + scaleY(MODULE_MARGIN_BOTTOM, null);
export const CATEGORIES_MODULE_HEIGHT = scaleY(CATEGORIES_MODULE_ITEM_HEIGHT, null) + scaleY(MODULE_TITLE_HEIGHT, null);

const NEXT_ITEM_VISIBLE_OFFSET_Y = scaleY(80, null);

export const LIST_MODULE_OFFSET_Y = WINDOW_HEIGHT - LIST_MODULE_HEIGHT - NEXT_ITEM_VISIBLE_OFFSET_Y;
export const LIST_MODULE_AUDIO_OFFSET_Y = WINDOW_HEIGHT - LIST_MODULE_AUDIO_HEIGHT - NEXT_ITEM_VISIBLE_OFFSET_Y;
export const ASSET_PROMO_OFFSET_Y = WINDOW_HEIGHT - ASSET_PROMO_MODULE_HEIGHT - NEXT_ITEM_VISIBLE_OFFSET_Y;
export const CATEGORIES_OFFSET_Y = WINDOW_HEIGHT - CATEGORIES_MODULE_HEIGHT - NEXT_ITEM_VISIBLE_OFFSET_Y;

type BaseModuleWithAssetIdsAssets = {
  assets: Array<Need<AssetV2, 'id'> & { image16x9WithTitle: Maybe<Need<AssetImage, 'url'>> }>;
};
type BaseModuleCollage = Need<ModuleCollage, 'id' | 'type' | 'title' | 'assetId' | 'version'> & {
  thumbnails: Array<Need<ModuleCollageThumbnail, 'url'>>;
};
type BaseModuleCollageCollection = Need<ModuleCollageCollection, 'id' | 'type' | 'title' | 'collectionId' | 'version'> & {
  logo: Need<ModuleCollageThumbnail, 'url' | 'alt'>;
  background: Need<ModuleCollageThumbnail, 'url' | 'alt'>;
  thumbnails: Array<Need<ModuleCollageThumbnail, 'url'>>;
};
type BaseModuleCollection = Need<ModuleCollection, 'id' | 'title' | 'type' | 'collectionType' | 'version'> & BaseModuleWithAssetIdsAssets;
type BaseModuleDynamicCollection = Need<ModuleDynamicCollection, 'id' | 'title' | 'type' | 'collectionType' | 'version'> &
  BaseModuleWithAssetIdsAssets;
type BaseModulePreviewCollection = Need<ModulePreviewCollection, 'id' | 'title' | 'type' | 'version'> & BaseModuleWithAssetIdsAssets;
export type BaseModuleAssetPromo = Need<ModuleAssetPromo, 'id' | 'title' | 'type' | 'version'> & { asset: Need<AssetV2, 'id'> };

export type BaseModuleCategories = Need<ModuleCategory, 'id' | 'title' | 'type' | 'version'> & {
  categories: Array<
    Need<CategoryV2, 'id' | 'title' | 'titleArtUrl' | 'thumbnailUrl'> & {
      page: {
        backgroundUrl: Maybe<string>;
      };
    }
  >;
};

export type BaseModule =
  | BaseModuleCollection
  | BaseModuleDynamicCollection
  | BaseModulePreviewCollection
  | BaseModuleCollage
  | BaseModuleCollageCollection
  | BaseModuleAssetPromo
  | BaseModuleCategories;

export type BaseListModule = Exclude<Exclude<BaseModule, BaseModuleAssetPromo>, BaseModuleCategories>;

export type ModuleActiveItem<T> = T & {
  index: number;
  layout: FocusableComponentLayout;
};

export type ModuleEventHandler<M, I> = {
  moduleOffsetTop: number;
  moduleIndex: number;
  module: M;
  item: I;
};
